// Failbot needs to load first so we get errors from system lite.
import '@github-ui/failbot/failbot-error'

// Browser polyfills
import 'smoothscroll-polyfill'
import '@oddbird/popover-polyfill'
import '@github/arianotify-polyfill'

// Trusted types support
import '@github-ui/trusted-types'
import '@github-ui/trusted-types-policies/default'

import {applyRemoveChildPatch} from '@github-ui/remove-child-patch'

import {apply} from '@github/browser-support'
import '@github-ui/fetch-overrides'

apply()
if (typeof document !== 'undefined') applyRemoveChildPatch()
